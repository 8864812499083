import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {demoItems, ItemType} from "smx-components";

export interface ITimelineState {
  inputItems: ItemType[],
}

const initialState: ITimelineState = {
  inputItems: demoItems,
};

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setterTimeline: (state, action: PayloadAction<Partial<ITimelineState>>) => ({ ...state, ...action.payload }),
  },
});

export const {
  setterTimeline,
} = timelineSlice.actions;

export default timelineSlice.reducer;
