import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

import WBox from 'containers/WBox';

import { useSitemaxUIController } from 'context';

function SidenavCollapse({ icon, name, children, active, noCollapse, open, ...rest }: any) {
    const [controller] = useSitemaxUIController();
    const { miniSidenav, transparentSidenav, sidenavColor } = controller;

    const collapseItem = (theme: any, ownerState: any) => {
        const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
        const { active, transparentSidenav } = ownerState;

        const { dark, white, text, transparent } = palette;
        const { xxl } = boxShadows;
        const { borderRadius } = borders;
        const { pxToRem } = functions;

        return {
            background: active && transparentSidenav ? white.main : transparent.main,
            color: active ? dark.main : text.main,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(16)}`,
            margin: `0 ${pxToRem(16)}`,
            borderRadius: borderRadius.md,
            cursor: 'pointer',
            userSelect: 'none',
            whiteSpace: 'nowrap',
            boxShadow: active && transparentSidenav ? xxl : 'none',
            [breakpoints.up('xl')]: {
                boxShadow: () => {
                    if (active) {
                        return transparentSidenav ? xxl : 'none';
                    }

                    return 'none';
                },
                transition: transitions.create('box-shadow', {
                    easing: transitions.easing.easeInOut,
                    duration: transitions.duration.shorter,
                }),
            },
        };
    };

    const collapseIconBox = (theme: any, ownerState: any) => {
        const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
        const { active, transparentSidenav, sidenavColor } = ownerState;

        const { white, info, light, gradients } = palette;
        const { md } = boxShadows;
        const { borderRadius } = borders;
        const { pxToRem } = functions;

        return {
            background: () => {
                if (active) {
                    return sidenavColor === 'default' ? info.main : palette[sidenavColor].main;
                }

                return light.main;
            },
            minWidth: pxToRem(32),
            minHeight: pxToRem(32),
            borderRadius: borderRadius.md,
            display: 'grid',
            placeItems: 'center',
            boxShadow: md,
            transition: transitions.create('margin', {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
            }),

            [breakpoints.up('xl')]: {
                background: () => {
                    let background;

                    if (!active) {
                        background = transparentSidenav ? white.main : light.main;
                    } else if (sidenavColor === 'default') {
                        background = info.main;
                    } else if (sidenavColor === 'warning') {
                        background = gradients.warning.main;
                    } else {
                        background = palette[sidenavColor].main;
                    }

                    return background;
                },
            },

            '& svg, svg g': {
                fill: active ? white.main : gradients.dark.state,
            },
        };
    };

    const collapseIcon = ({ palette: { white, gradients } }: any, ownerState: any) => ({
        color: active ? white.main : gradients.dark.state,
    });

    const collapseText = (theme: any, ownerState: any) => {
        const { typography, transitions, breakpoints, functions } = theme;
        const { miniSidenav, transparentSidenav, active } = ownerState;

        const { size, fontWeightMedium, fontWeightRegular } = typography;
        const { pxToRem } = functions;

        return {
            marginLeft: pxToRem(12.8),

            [breakpoints.up('lg')]: {
                opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
                maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : '100%',
                marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(12.8),
                transition: transitions.create(['opacity', 'margin'], {
                    easing: transitions.easing.easeInOut,
                    duration: transitions.duration.standard,
                }),
            },

            '& span': {
                fontWeight: active ? fontWeightMedium : fontWeightRegular,
                fontSize: size.md,
                lineHeight: 0,
            },
        };
    };

    const collapseArrow = (theme: any, ownerState: any) => {
        const { palette, typography, transitions, breakpoints, functions } = theme;
        const { noCollapse, transparentSidenav, miniSidenav, open } = ownerState;

        const { dark, gradients } = palette;
        const { size } = typography;
        const { pxToRem, rgba } = functions;

        return {
            fontSize: `${size.lg} !important`,
            fontWeight: 700,
            marginBottom: pxToRem(-1),
            transform: open ? 'rotate(0)' : 'rotate(-180deg)',
            color: open ? dark.main : rgba(gradients.dark.state, 0.4),
            transition: transitions.create(['color', 'transform', 'opacity'], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
            }),

            [breakpoints.up('xl')]: {
                display:
                    noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
                        ? 'none !important'
                        : 'block !important',
            },
        };
    };

    return (
        <>
            <ListItem component="li">
                <WBox {...rest} sx={(theme: any) => collapseItem(theme, { active, transparentSidenav })}>
                    <ListItemIcon
                        sx={(theme: any) => collapseIconBox(theme, { active, transparentSidenav, sidenavColor })}
                    >
                        {typeof icon === "string" ? (
                            <Icon sx={(theme: any) => collapseIcon(theme, { active })}>{icon}</Icon>
                        ) : (
                            icon
                        )}
                    </ListItemIcon>

                    <ListItemText
                        primary={name}
                        sx={(theme: any) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
                    />

                    <Icon
                        sx={(theme: any) =>
                            collapseArrow(theme, { noCollapse, transparentSidenav, miniSidenav, open })
                        }
                    >
                        expand_less
                    </Icon>
                </WBox>
            </ListItem>
            {children && (
                <Collapse in={open} unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    );
}

export default SidenavCollapse;
