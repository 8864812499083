import {
    configureStore,
    combineReducers
} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import accountReducer from 'slices/accountSlice';
import authReducer from 'slices/authSlice';
import categoriesReducer from 'slices/categoriesSlice';
import categoryReducer from 'slices/categorySlice';
import contactsReducer from 'slices/contactsSlice';
import documentReducer from 'slices/documentSlice';
import documentsReducer from 'slices/documentsSlice';
import navigationItemsReducer from 'slices/navigationItemsSlice';
import progressReducer from 'slices/progressSlice';
import projectReducer from 'modules/projects/slices/projectSlice';
import projectsReducer from 'modules/projects/slices/projectsSlice';
import shareablesReducer from 'slices/shareablesSlice';
import snackbarsReducer, {
    handleFulfilledMiddleware,
    handleRejectedMiddleware
} from 'slices/snackbarsSlice';
import teamReducer from 'slices/teamSlice';
import teamsReducer from 'slices/teamsSlice';
import tenantsReducer from 'slices/tenantsSlice';
import usersReducer from 'slices/usersSlice';
import dashboardsReducer from 'modules/dashboards/slices/dashboardsSlice';
// TODO: handle old projects slice and rename this one
import integrationsReducer from 'slices/integrationsSlice';
import invitationsReducer from 'slices/invitationsSlice';
import timelineReducer from 'slices/timelineSlice';
import costCodesReducer from 'modules/settings/slices/costCodesSlice';
import divisionsReducer from 'modules/settings/slices/divisionsSlice';
import classificationsReducer from 'modules/settings/slices/classificationsSlice';
import phasesReducer from 'modules/settings/slices/phasesSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    account: accountReducer,
    category: categoryReducer,
    categories: categoriesReducer,
    classifications: classificationsReducer,
    contacts: contactsReducer,
    costCodes: costCodesReducer,
    dashboards: dashboardsReducer,
    divisions: divisionsReducer,
    document: documentReducer,
    documents: documentsReducer,
    invitations: invitationsReducer,
    integrations: integrationsReducer,
    navigationItems: navigationItemsReducer,
    progress: progressReducer,
    shareables: shareablesReducer,
    snackbars: snackbarsReducer,
    tenants: tenantsReducer,
    teams: teamsReducer,
    team: teamReducer,
    phases: phasesReducer,
    projects: projectsReducer,
    project: projectReducer,
    users: usersReducer,
    timeline: timelineReducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware()
            .concat(thunk)
            .prepend(handleRejectedMiddleware.middleware)
            .prepend(handleFulfilledMiddleware.middleware),
        preloadedState,
    });
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']