import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

import WBox from 'containers/WBox';
import { item, itemContent, itemArrow } from 'components/SideNav/styles/sidenavItem';
import { useSitemaxUIController } from 'context';

const SidenavItem = ({ name, active, nested, children, open, ...rest }: any) => {
    const [controller] = useSitemaxUIController();
    const { miniSidenav } = controller;

    return (
        <>
            <ListItem {...rest} component="li" sx={item}>
                <WBox sx={(theme: any) => itemContent(theme, { active, miniSidenav, name, nested })}>
                    <ListItemText primary={name} />
                    {children && (
                        <Icon component="i" sx={(theme: any) => itemArrow(theme, { open, miniSidenav })}>
                            expand_less
                        </Icon>
                    )}
                </WBox>
            </ListItem>
            {children && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    );
}

export default SidenavItem;