import {Calendar, ItemType} from "smx-components";
import {useAppSelector} from '../../../../utilities/hooks';
import usePaddingTimeline from '../../../../hooks/usePaddingTimeline';

const CalendarPage = () => {
    const paddingBottom = usePaddingTimeline();
    const inputItems = useAppSelector<ItemType[]>((state) => state.timeline.inputItems);

    return <Calendar inputItems={inputItems} paddingBottom={paddingBottom}/>;
}

export default CalendarPage;