import {
    DashboardCustomize,
    Domain,
    EventNote,
    LineStyle,
    PermMediaRounded, SettingsRounded,
    SupervisorAccount
} from '@mui/icons-material';

export type RoutesTypeType = 'collapse' | 'title' | 'divider';
export type RoutePermissionType = 'documents.destroyForever' | 'categories.destroyForever';

export type RouteCollapseType = {
    name: string,
    key: string,
    route: string,
    permissions?: RoutePermissionType[],
    collapse?: RouteCollapseType[],
    href?: string
};

export type RoutesType = {
    type: RoutesTypeType,
    name?: string,
    key: string,
    icon?: JSX.Element,
    route?: string,
    noCollapse?: boolean,
    title?: string
    collapse?: RouteCollapseType[],
    href?: string
};

export const routes: RoutesType[] = [
    {
        type: 'collapse',
        name: 'Dashboards',
        key: 'dashboards',
        icon: <DashboardCustomize fontSize='small' />,
        route: '/dashboards',
        noCollapse: true,
    },
    {
        type: 'title',
        title: 'Pages',
        key: 'title-pages'
    },
    {
        type: 'collapse',
        name: 'Projects',
        key: 'projects',
        icon: <Domain fontSize='small' />,
        route: '/projects',
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Users',
        key: 'users',
        icon: <SupervisorAccount fontSize='small' />,
        route: '/users',
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Documents',
        key: 'documents',
        route: '/documents',
        icon: <PermMediaRounded fontSize='small' />,
        collapse: [
            {
                name: 'Documents',
                key: 'documents',
                route: '/documents',
            },
            {
                name: 'Shared with me',
                key: 'shared',
                route: '/documents/shared',
            },
            {
                name: 'Deleted files',
                key: 'trash',
                route: '/documents/trash',
                permissions: ['documents.destroyForever', 'categories.destroyForever']
            }
        ],
    },
    {
        type: 'collapse',
        name: 'Calendar',
        key: 'calendar',
        icon: <EventNote fontSize='small' />,
        route: '/calendar',
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Timeline',
        key: 'timeline',
        icon: <LineStyle fontSize='small' />,
        route: '/timeline',
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Settings',
        key: 'settings',
        icon: <SettingsRounded fontSize='small' />,
        route: '/settings',
        noCollapse: true,
    },
];
