import {useEffect, useState} from "react";
import useEmailPhoneUserVerification from './useEmailPhoneUserVerification';

const usePaddingTimeline = () => {
    const {isShowEmailVerificationAlert, isShowPhoneVerificationAlert} = useEmailPhoneUserVerification();
    const [paddingBottom, setPaddingBottom] = useState(0);

    useEffect(() => {
        setPaddingBottom(isShowEmailVerificationAlert || isShowPhoneVerificationAlert ? 30 : 31);
    }, [isShowEmailVerificationAlert, isShowPhoneVerificationAlert]);

    return paddingBottom;
};

export default usePaddingTimeline;
