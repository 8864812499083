import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';

import './index.scss';

import App from 'App';
import { setupStore } from 'store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const preloadedState = {};
const store = setupStore(preloadedState);

root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
