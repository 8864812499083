import List from '@mui/material/List';

function SidenavList({ children }: any) {
    return (
        <List
            sx={{
                pl: 2,
                ml: 3,
            }}
        >
            {children}
        </List>
    );
}

export default SidenavList;