import { createTheme } from '@mui/material/styles';

import boxShadows from 'assets/theme/base/boxShadows';
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import breakpoints from 'assets/theme/base/breakpoints';

import appBar from 'assets/theme/components/appBar';
import sidenav from 'assets/theme/components/sidenav';
import list from 'assets/theme/components/list';
import listItem from 'assets/theme/components/list/listItem';
import listItemText from 'assets/theme/components/list/listItemText';
import divider from 'assets/theme/components/divider';

import boxShadow from 'assets/theme/functions/boxShadow';
import pxToRem from 'assets/theme/functions/pxToRem';
import rgba from 'assets/theme/functions/rgba';
import linearGradient from 'assets/theme/functions/linearGradient';

declare module '@mui/material/styles' {
    interface Theme {
        functions: any;
        boxShadows: any;
        borders: any;
    }
    interface ThemeOptions {
        functions: any;
        boxShadows: any;
        borders: any;
    }
    interface PaletteOptions {
        transparent?: any;
        white?: any;
        black?: any;
        light?: any;
        dark?: any;
        gradients?: any;
        socialMediaColors?: any;
        alertColors?: any;
        badgeColors?: any;
        inputColors?: any;
        sliderColors?: any;
        circleSliderColors?: any;
        tabs?: any;
    }
    interface TypeText {
        main: string;
        focus: string;
    }
    interface TypographyVariants {
        d1?: any;
        d2?: any;
        d3?: any;
        d4?: any;
        d5?: any;
        d6?: any;
        size?: any;
        lineHeight?: any;
    }
    interface TypographyVariantsOptions {
        d1?: any;
        d2?: any;
        d3?: any;
        d4?: any;
        d5?: any;
        d6?: any;
        size?: any;
        lineHeight?: any;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        d1?: any;
        d2?: any;
        d3?: any;
        d4?: any;
        d5?: any;
        d6?: any;
        size?: any;
        lineHeight?: any;
    }
}

const { dark } = colors;

const baseProperties = {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSizeXXS: pxToRem(8.4),
    fontSizeXS: pxToRem(10),
    fontSizeSM: pxToRem(12),
    fontSizeMD: pxToRem(14),
    fontSizeLG: pxToRem(16),
    fontSizeXL: pxToRem(18),
};

const baseHeadingProperties = {
    fontFamily: baseProperties.fontFamily,
    color: dark.main,
    fontWeight: baseProperties.fontWeightMedium,
};

const baseDisplayProperties = {
    fontFamily: baseProperties.fontFamily,
    color: dark.main,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.2,
};

export default createTheme({
    breakpoints: { ...breakpoints },
    palette: { ...colors },
    shape: {
        borderRadius: 6,
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiDrawer: { ...sidenav },
        MuiList: { ...list },
        MuiListItem: { ...listItem },
        MuiListItemText: { ...listItemText },
        MuiAppBar: { ...appBar },
        MuiDivider: { ...divider },
    },
    functions: {
        boxShadow,
        pxToRem,
        rgba,
        linearGradient,
    },
    boxShadows: { ...boxShadows },
    borders: { ...borders },
    typography: {
        fontFamily: baseProperties.fontFamily,
        fontWeightLight: baseProperties.fontWeightLight,
        fontWeightRegular: baseProperties.fontWeightRegular,
        fontWeightMedium: baseProperties.fontWeightMedium,
        fontWeightBold: baseProperties.fontWeightBold,

        h1: {
            fontSize: pxToRem(48),
            lineHeight: 1.25,
            ...baseHeadingProperties,
        },

        h2: {
            fontSize: pxToRem(36),
            lineHeight: 1.3,
            ...baseHeadingProperties,
        },

        h3: {
            fontSize: pxToRem(30),
            lineHeight: 1.375,
            ...baseHeadingProperties,
        },

        h4: {
            fontSize: pxToRem(24),
            lineHeight: 1.375,
            ...baseHeadingProperties,
        },

        h5: {
            fontSize: pxToRem(20),
            lineHeight: 1.375,
            ...baseHeadingProperties,
        },

        h6: {
            fontSize: pxToRem(16),
            lineHeight: 1.625,
            ...baseHeadingProperties,
        },

        subtitle1: {
            fontFamily: baseProperties.fontFamily,
            fontSize: baseProperties.fontSizeLG,
            fontWeight: baseProperties.fontWeightRegular,
            lineHeight: 1.625,
        },

        subtitle2: {
            fontFamily: baseProperties.fontFamily,
            fontSize: baseProperties.fontSizeSM,
            fontWeight: baseProperties.fontWeightMedium,
            lineHeight: 1.6,
        },

        body1: {
            fontFamily: baseProperties.fontFamily,
            fontSize: baseProperties.fontSizeLG,
            fontWeight: baseProperties.fontWeightRegular,
            lineHeight: 1.625,
        },

        body2: {
            fontFamily: baseProperties.fontFamily,
            fontSize: baseProperties.fontSizeMD,
            fontWeight: baseProperties.fontWeightRegular,
            lineHeight: 1.6,
        },

        button: {
            fontFamily: baseProperties.fontFamily,
            fontSize: baseProperties.fontSizeSM,
            fontWeight: baseProperties.fontWeightBold,
            lineHeight: 1.5,
            textTransform: "uppercase",
        },

        caption: {
            fontFamily: baseProperties.fontFamily,
            fontSize: baseProperties.fontSizeXS,
            fontWeight: baseProperties.fontWeightRegular,
            lineHeight: 1.25,
        },

        overline: {
            fontFamily: baseProperties.fontFamily,
            fontSize: baseProperties.fontSizeXS,
        },

        d1: {
            fontSize: pxToRem(80),
            ...baseDisplayProperties,
        },

        d2: {
            fontSize: pxToRem(72),
            ...baseDisplayProperties,
        },

        d3: {
            fontSize: pxToRem(64),
            ...baseDisplayProperties,
        },

        d4: {
            fontSize: pxToRem(56),
            ...baseDisplayProperties,
        },

        d5: {
            fontSize: pxToRem(48),
            ...baseDisplayProperties,
        },

        d6: {
            fontSize: pxToRem(40),
            ...baseDisplayProperties,
        },

        size: {
            xxs: baseProperties.fontSizeXXS,
            xs: baseProperties.fontSizeXS,
            sm: baseProperties.fontSizeSM,
            md: baseProperties.fontSizeMD,
            lg: baseProperties.fontSizeLG,
            xl: baseProperties.fontSizeXL,
        },

        lineHeight: {
            sm: 1.25,
            md: 1.5,
            lg: 2,
        },
    },
});