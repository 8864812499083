import {
    AnyAction,
    createListenerMiddleware,
    createSlice,
    isFulfilled,
    isRejected,
    nanoid
} from '@reduxjs/toolkit';
import {
    closeSnackbar,
    enqueueSnackbar
} from 'notistack';
import {
    removeAccessAndReset
} from 'slices/authSlice';
import { REQUEST_CODES } from 'utilities/constants';

export enum SnackbarTypes {
    DEFAULT = 'default',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success',
};

export interface SnackbarItem {
    id: string;
    type: SnackbarTypes;
    message: string;
    isOpen: boolean;
};

export interface SnackbarItems extends Array<SnackbarItem> { };

interface SnackbarItemsState {
    items: SnackbarItems;
};

export const initialState: SnackbarItemsState = {
    items: [],
};

const _make = (payload: any) => {
    return {
        id: payload.id || nanoid(),
        type: payload.type || SnackbarTypes.ERROR,
        message: payload.message,
        isOpen: true,
    };
};

const _add = (state: any, payload: any) => {
    if (!enqueueSnackbar) return;

    const id = enqueueSnackbar(payload.message, {
        variant: payload.type,
    });

    const error: SnackbarItem = _make({
        id: id,
        ...payload
    });

    state.items.push(error);
};

const _remove = (state: any, payload: any) => {
    if (!state.items) return;

    let errors = state.items.filter((item: any) => item.id !== payload);

    closeSnackbar(payload);

    state.items = errors;
};

const snackbarsSlice = createSlice({
    name: 'snackbars',
    initialState: initialState,
    reducers: {
        reset: () => initialState,
        addSnackbar: (state, action) => {
            _add(state, action.payload);
        },
        removeSnackbar: (state, action) => {
            _remove(state, action.payload);
        },
    },
    extraReducers: builder => {}
});

export const handleRejectedMiddleware = createListenerMiddleware();
export const handleFulfilledMiddleware = createListenerMiddleware();

handleRejectedMiddleware.startListening({
    predicate: (action: AnyAction) => {
        return isRejected(action);
    },
    effect: (action: AnyAction, { dispatch }) => {
        switch (action.payload?.status) {
            case REQUEST_CODES.OK:
            case REQUEST_CODES.CREATED:
                const message = action.payload?.message;
                if (message) {
                    dispatch(snackbarsSlice.actions.addSnackbar({
                        type: SnackbarTypes.SUCCESS,
                        message: message,
                    }));
                }
                break;
            case REQUEST_CODES.UNAUTHORIZED:
                dispatch(snackbarsSlice.actions.addSnackbar(action.payload.data));
                dispatch(removeAccessAndReset());
                break;
            case REQUEST_CODES.CANCELED:
                console.log('rejectedMiddleware', REQUEST_CODES.CANCELED);
                break;
            default:
                dispatch(snackbarsSlice.actions.addSnackbar(action.payload.data));
                break;
        }
    },
});

handleFulfilledMiddleware.startListening({
    predicate: (action: AnyAction) => {
        return isFulfilled(action);
    },
    effect: (action: AnyAction, { dispatch }) => {
        const message = action.payload?.message;
        if (message) {
            dispatch(snackbarsSlice.actions.addSnackbar({
                type: SnackbarTypes.SUCCESS,
                message: message,
            }));
        }
    },
});

export const { reset, addSnackbar, removeSnackbar } = snackbarsSlice.actions;
export default snackbarsSlice.reducer;