import {
    ExpandMore,
    FilterList,
} from '@mui/icons-material';
import {
    TextField,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    ListItemIconProps,
    Button,
} from '@mui/material';
import React, { forwardRef, useState, useRef, cloneElement, ReactElement, RefObject } from 'react';
import { FilterModalProps } from 'modals/FilterModal';
import { BaseModalRef } from 'modals/BaseModal';
import { GridColDef, GridActionsCellItem } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';

import { GridEventListener, GridRowParams } from '@mui/x-data-grid';
import DefaultDataGrid from 'components/DefaultDataGrid';
import { useAppSelector } from 'utilities/hooks';
import { isAllowed } from 'components/UserAllowed/UserAllowed';
import {
    usePopupState,
    bindTrigger,
    bindMenu,
} from 'material-ui-popup-state/hooks'

export interface SitemaxTableHeader {
    headerName?: string,
    field?: string,
    style?: object,
    width?: number,
    sort?: string,
    cellClassName?: string,
    headerClassName?: string,
    renderCell?: (row: object) => any,
    defaultSort?: string,
    permission?: string,
    optional?: boolean,
    map?: (value: any) => string,
    actions?: Array<SitemaxTableActionItem>,
    icon?: ReactElement<ListItemIconProps>;
    resizable?: boolean,
    sortable?: boolean,
    onClick?: (row: object) => any,
}

export interface SitemaxTableActionItem {
    title?: string;
    selectionRequired?: boolean;
    onClick?: (row: any | Array<any>) => any;
    icon?: ReactElement<ListItemIconProps>;
    hidden?: boolean | ((row?: any) => boolean);
    disabled?: boolean | ((row?: any) => boolean);
    divider?: boolean;
    permissions?: Array<string>;
}

export interface SitemaxFilterModalProps extends FilterModalProps {
    ref: RefObject<BaseModalRef>;
}

export interface SitemaxTableProps {
    headers: Array<SitemaxTableHeader>;
    rows: Array<any>;
    getData: (params: any) => void;
    filtersModal?: ReactElement<SitemaxFilterModalProps>;
    loading?: boolean;
    actions?: Array<SitemaxTableActionItem>,
    disableVirtualization?: boolean,
    checkboxSelection?: boolean,
    onCellClick?: GridEventListener<'cellClick'>,
}

export function SitemaxTable({
    headers,
    rows,
    getData,
    filtersModal,
    loading,
    actions,
    disableVirtualization,
    checkboxSelection,
    onCellClick
}: SitemaxTableProps, ref: any) {
    const [search, setSearch] = useState('');
    const [rowsSelected, setRowsSelected] = useState<Array<string>>([]);
    const filterModalRef = useRef<BaseModalRef>(null);
    let _search = search;

    // TODO: when server side is complete, change filters to use a real type which should extract some of the shared filters
    function fetch(filters?: any) {
        let params: any = {};
        if (_search) {
            setSearch(_search);
            params.search = _search || undefined;
        };
        getData(params);
    };

    const _filtersModal = filtersModal ? cloneElement(filtersModal, {
        ref: filterModalRef,
        onSubmit: fetch,
    }, null) : null;

    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { currentTeam } = useAppSelector((state) => state.teams);
    const { permissions } = useAppSelector((state) => state.account);
    let columns = headers.map((header) => {
        if (header.actions) {
            return {
                field: 'actions',
                type: 'actions',
                getActions: (params: GridRowParams) => {
                    var _actions = header.actions!.filter(action => {
                        if (typeof action.hidden == 'boolean' && action.hidden) return false;
                        if (typeof action.hidden == 'function' && action.hidden(params.row)) return false;
                        if (action.permissions && action.permissions.length > 0) {
                            if (!isAllowed({ permissions: action.permissions }, currentTenant, currentTeam, permissions)) return false;
                        }
                        return true;
                    });
                    return _actions!.map((action: any) => {
                        return <GridActionsCellItem icon={action.icon} showInMenu onClick={() => action.onClick && action.onClick(params.row)} label={action.title} />;
                    });
                },
            } as GridColDef;
        }
        return header as GridColDef;
    });
    const popupState = usePopupState({ variant: 'popover', popupId: 'actionsMenu' });
    if (actions) {
        actions = actions.filter(action => {
            if (typeof action.hidden == 'boolean' && action.hidden) return false;
            if (typeof action.hidden == 'function' && action.hidden(rowsSelected)) return false;
            return true;
        });
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px', }}>
                <form onSubmit={fetch}>
                    <TextField
                        placeholder="Search"
                        variant="outlined"
                        size="small"
                        fullWidth
                        defaultValue={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            _search = event.target.value;
                        }}
                        InputProps={ _filtersModal ? {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => filterModalRef?.current?.open()}>
                                        <FilterList />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        } : undefined }
                    />
                </form>
                {
                    actions && actions.length > 0 &&
                    <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        {...bindTrigger(popupState)}
                        endIcon={<ExpandMore />}
                    >
                        Actions
                    </Button>
                }
            </div>
            <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                {
                    actions && actions.map((action: any, idx: number) => {
                        var disabled = false;
                        if (typeof action.disabled == 'boolean' && action.disabled) disabled = true;
                        if (typeof action.disabled == 'function' && action.disabled(rowsSelected)) disabled = true;
                        if (action.selectionRequired && rowsSelected.length == 0) disabled = true;
                        return <MenuItem
                            key={`action-${idx}`}
                            onClick={() => {
                                const selected = rowsSelected;
                                action.onClick && action.onClick(selected);
                                popupState.close();
                            }}
                            disabled={disabled}
                        >
                            {action.title}
                        </MenuItem>;
                    })
                }
            </Menu>
            <DefaultDataGrid
                rows={rows}
                onRowSelectionModelChange={(ids) => {
                    const selected = rows.filter((row) => ids.includes(row.id));
                    setRowsSelected(selected as any);
                }}
                columns={columns}
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection={checkboxSelection}
                disableRowSelectionOnClick
                disableVirtualization={disableVirtualization}
                onCellClick={onCellClick}
            />
            {_filtersModal}
        </>
    );
}

export default forwardRef(SitemaxTable);