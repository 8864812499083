import {
    Button,
    Container,
    Stack
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import ControlledGridStack from 'modules/dashboards/components/ControlledGridStack';
import { ControlledGridStackRef } from 'modules/dashboards/components/ControlledGridStack/ControlledGridStack';
import {
    DashboardsContext
} from 'modules/dashboards/interfaces/dashboards';
import {
    setCurrentProjectById
} from 'modules/projects/slices/projectsSlice';
import {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import {
    fetchDefaultDashboard,
    saveDashboardMetadata
} from 'modules/dashboards/slices/dashboardsActions';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';


const DashboardsPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const gridStackRef = useRef<ControlledGridStackRef>(null);
    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { defaultDashboard } = useAppSelector((state) => state.dashboards);

    const [items, setItems] = useState<Array<any>>([]);

    useEffect(() => {
        dispatch(fetchDefaultDashboard());
    }, [location, currentTenant]);

    useEffect(() => {
        if (!defaultDashboard) return;
        if (!defaultDashboard.metadata) return;

        setItems(defaultDashboard.metadata);
    }, [defaultDashboard]);

    const widgets = [
        {
            name: 'BarChartWithData',
            props: {
                config: [{ scaleType: 'band', data: ['Group A', 'Group B', 'Group C'] }],
            },
        },
        {
            name: 'LineChartWithData',
        },
        {
            name: 'PieChartWithData',
        },
        {
            name: 'SiteMaxFormsListCreatedIn30Days',
        },
        {
            name: 'SiteMaxToolsMaintenanceExpiring',
        },
        {
            name: 'SiteMaxUsersRecordsExpiring',
        },
        {
            name: 'SiteMaxDocumentsStorageInfo',
        }
    ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (!index) return;
        if (index == widgets.length) setIndex(0);
    }, [index]);

    const handleAddWidget = () => {
        const id = 'widget_' + nanoid();

        setItems([
            ...items,
            {
                id: id,
                title: 'Widget title',
                subtitle: 'Subtitle: ' + widgets[index].name,
                position: {
                    id: id,
                    w: 6,
                    h: 9,
                },
                widget: widgets[index]
            },
        ]);

        setIndex(index + 1);
    };

    const handleSave = () => {
        const metadata = gridStackRef.current?.save();
        dispatch(saveDashboardMetadata(Object.assign({ ...defaultDashboard }, {
            metadata: metadata,
        })));
    };

    return <Container component="main" maxWidth={false}>
        <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}>
            <Button onClick={handleAddWidget}>Add Widget</Button>
            <Button onClick={handleSave}>Save</Button>
        </Stack>
        <DashboardsContext.Provider value={defaultDashboard}>
            <ControlledGridStack
                ref={gridStackRef}
                items={items}
                options={{
                    float: false,
                    sizeToContent: false,
                    cellHeight: '50px',
                }}
                onChange={() => {
                    console.log('onChange');
                }}
                onDelete={(id: string) => {
                    setItems(items.filter((item) => item.id !== id));
                }}
                sx={{
                    mt: 2,
                }}
            />
        </DashboardsContext.Provider>
    </Container>;
};

export default DashboardsPage;