import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import { useLocation, NavLink } from 'react-router-dom';
import { useSitemaxUIController, setMiniSidenav } from 'context';

import SidenavCollapse from './SideNavCollapse';
import SidenavList from './SideNavList';
import SidenavItem from './SidenavItem';
import SidenavRoot from './SidenavRoot';

import WBox from 'containers/WBox';
import WTypography from 'containers/WTypography';

import UserAllowed from 'components/UserAllowed';
import {RouteCollapseType, routes, RoutesType} from './routes';
import {DashboardCustomize, SupervisorAccount} from '@mui/icons-material';


function projectRoutes (projectId: string) {
    return [
        {
            type: 'collapse',
            name: 'Dashboards',
            key: 'dashboards',
            icon: <DashboardCustomize fontSize='small' />,
            route: `/projects/${projectId}/dashboards`,
            noCollapse: true,
        },
        {
            type: 'title',
            title: 'Pages',
            key: 'title-pages'
        },
        {
            type: 'collapse',
            name: 'Users',
            key: 'users',
            icon: <SupervisorAccount fontSize='small' />,
            route: `/projects/${projectId}/users`,
            noCollapse: true,
        },
    ];
}

const SideNav = ({ ...rest }) => {
    const theme = useTheme();
    const location = useLocation();
    const [controller, dispatchUI] = useSitemaxUIController();
    const { miniSidenav, transparentSidenav } = controller;
    const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
    const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string>(false);

    const { pathname } = location;
    const [collapseName, itemName] = pathname.split('/').slice(1);

    const closeSidenav = () => setMiniSidenav(dispatchUI, true);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        const handleMiniSidenav = () => {
            setMiniSidenav(dispatchUI, window.innerWidth < 1200);
        };

        // The event listener that's calling the handleMiniSidenav function when resizing the window.
        window.addEventListener('resize', handleMiniSidenav);
        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleMiniSidenav);
    }, [dispatchUI, location]);

    const getParamLink = (href: string, key?: string) => {
        return {
            key: key,
            href: href,
            target: "_blank",
            rel: "noreferrer",
            sx: { textDecoration: "none" }
        }
    };

    const renderLink = (name: string, href: string, key?: string) => <Link {...getParamLink(href, key)} >
        <SidenavItem name={name} nested />
    </Link>;

    const renderNavLink = (name: string, route: string, key?: string) => <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
        <SidenavItem name={name} active={route === pathname} nested />
    </NavLink>;

    const renderNestedCollapse = (collapse: RouteCollapseType[]) => collapse.map(({ name, route, key, href, permissions }) => {
        if (permissions) {
            return <UserAllowed key={key} permissions={permissions}>
                { href ? renderLink(name, href) : renderNavLink(name, route) }
            </UserAllowed>
        }

        return href ? renderLink(name, href, key) : renderNavLink(name, route, key);
    });

    // Render the all the collapses from the routes.js
    const renderCollapse = (collapses: RouteCollapseType[]) => collapses.map(({ name, collapse, route, href, key, permissions }) => {
        let returnValue;

        if (collapse) {
            returnValue = (
                <SidenavItem
                    key={key}
                    name={name}
                    active={key === itemName}
                    open={openNestedCollapse === name}
                    onClick={() =>
                        openNestedCollapse === name
                            ? setOpenNestedCollapse(false)
                            : setOpenNestedCollapse(name)
                    }
                >
                    { renderNestedCollapse(collapse) }
                </SidenavItem>
            );
        } else {
            const sidenavItem = <SidenavItem name={name} active={key === itemName} />;
            returnValue = href ? (
                <Link {...getParamLink(href, key)} >
                    { sidenavItem }
                </Link>
            ) : (
                <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
                    { sidenavItem }
                </NavLink>
            );
        }

        if (permissions) {
            return <UserAllowed key={key} permissions={permissions}>
                <SidenavList>{returnValue}</SidenavList>
            </UserAllowed>;
        }

        return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

    const getCollapseElement = ({ name, icon, collapse, noCollapse, key, href, route }: Partial<RoutesType>) => {
        const paramSidenavCollapse = {
            name: name,
            icon: icon,
            active: key === collapseName
        };

        if (href) {
            return (
                <Link {...getParamLink(href, key)} >
                    <SidenavCollapse
                        {...paramSidenavCollapse}
                        noCollapse={noCollapse}
                    />
                </Link>
            );
        } else {
            const sidenavCollapseContent = collapse ? renderCollapse(collapse) : null;

            if (noCollapse && route) {
                return (
                    <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
                        <SidenavCollapse
                            {...paramSidenavCollapse}
                            noCollapse={noCollapse}
                        >
                            {sidenavCollapseContent}
                        </SidenavCollapse>
                    </NavLink>
                );
            } else {
                return (
                    <SidenavCollapse
                        {...paramSidenavCollapse}
                        key={key}
                        open={openCollapse === key}
                        onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key!))}
                    >
                        {sidenavCollapseContent}
                    </SidenavCollapse>
                );
            }
        }
    };

    const renderRoutes = routes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        const titleElement = <WTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
        >
            {title}
        </WTypography>;

        switch (type){
            case "collapse": return getCollapseElement({ name, icon, collapse, noCollapse, key, href, route });
            case "title": return titleElement;
            case "divider": return <Divider key={key} />
            default: return null;
        }
    });

    return <>
        <SidenavRoot {...rest} theme={theme} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
            <WBox pt={3} pb={1} px={4} textAlign="center">
                <WBox
                    display={{ xs: "block", xl: "none" }}
                    position="absolute"
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{ cursor: "pointer" }}
                >
                    <WTypography variant="h6" color="secondary">
                        <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                    </WTypography>
                </WBox>
                <WBox component={NavLink} to="/" display="flex" alignItems="center">
                    <WBox
                        component="img"
                        src={"https://sitemaxsystems.com/wp-content/uploads/2023/12/SMX-Icon.png"}
                        alt="Soft UI Logo"
                        width="2rem"
                    />
                </WBox>
            </WBox>
            <Divider />
            <List>
                {renderRoutes}
            </List>
            {/* {!miniSidenav && <WBox mt={3} px={2}>
                <StorageCounter />
            </WBox>} */}
        </SidenavRoot>
    </>;
};

export default SideNav;