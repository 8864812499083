import {
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    useNavigate,
    useParams
} from 'react-router-dom';
import {
    fetchCategories,
    moveCategory,
    softDeleteCategory,
} from 'slices/categoriesActions';
import {
    fetchDocuments,
} from 'slices/documentsActions';
import {
    setExcluded as setExcludedCategories
} from 'slices/categoriesSlice';
import {
    fetchProjects
} from 'modules/projects/slices/projectsActions';
import {
    resetCurrentProject,
    setCurrentProject
} from 'modules/projects/slices/projectsSlice';
import {
    fetchTeams,
    searchTeams,
    softDeleteTeam,
} from 'slices/teamsActions';
import {
    resetCurrentTeam,
    setCurrentTeam,
    setCurrentTeamById,
} from 'slices/teamsSlice';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';
import DocumentsTable from '../components/DocumentsTable';
import { Documents } from 'interfaces/documents';
import { Button, ButtonGroup, Divider, Grid, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Stack, Typography } from '@mui/material';
import CustomDropdown from 'components/CustomDropdown';
import { Close, DeleteSweep, DriveFileMove, People, Share, SyncAlt } from '@mui/icons-material';
import { bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import { EntityContext, ProjectsContext, Team, TeamsContext } from 'interfaces/teams';
import ProjectTeamCreateModal from 'modals/ProjectTeamCreateModal';
import { BaseModalRef } from 'modals/BaseModal';
import TeamCreateModal from 'modals/TeamCreateModal';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import TeamRenameModal from 'modals/TeamRenameModal';
import { syncIntegration } from 'slices/integrationsActions';
import TeamMembersUpdateModal from 'modals/TeamMembersUpdateModal';
import ConfirmationModal from 'modals/ConfirmationModal';
import CategoryShareModal from 'modals/CategoryShareModal';
import { setCategory } from 'slices/categorySlice';
import GuestTeamCreateModal from 'modals/GuestTeamCreateModal';
import GuestTeamMembersUpdateModal from 'modals/GuestTeamMembersUpdateModal';
import CategoriesTreeModal from 'modals/CategoriesTreeModal';

const DefaultDocumentsPage = ({ }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        teamId,
        categoryId: categoryIdString,
    } = useParams();
    const categoryId = categoryIdString ? parseInt(categoryIdString) : null;

    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { teams: teamsList, projectTeams: projectTeamsList, currentTeam } = useAppSelector((state) => state.teams);
    const { projects: projectsList, currentProject, loading: projectsLoading } = useAppSelector((state) => state.projects);
    const { integrations } = useAppSelector((state) => state.integrations);
    const [currentBreadcrumb, setCurrentBreadcrumb] = useState<any | null>(null);
    const [currentEntity, setCurrentEntity] = useState<null | any>(null);
    const teamRenameModal = useRef<BaseModalRef>(null);
    const teamMembersUpdateModal = useRef<BaseModalRef>(null);
    const projectTeamCreateModal = useRef<BaseModalRef>(null);
    const confirmTeamDeleteModal = useRef<BaseModalRef>(null);
    const confirmDeleteBreadcrumbModal = useRef<BaseModalRef>(null);
    const teamCreateModal = useRef<BaseModalRef>(null);
    const guestTeamCreateModal = useRef<BaseModalRef>(null);
    const guestTeamMembersUpdateModal = useRef<BaseModalRef>(null);
    const moveRowModal = useRef<BaseModalRef>(null);
    const categoryShareModal = useRef<BaseModalRef>(null);

    const {
        list,
        teams,
        breadcrumbs,
        loading,
    } = useAppSelector((state) => state.documents);

    /**
     * Go to folder
     */
    useEffect(() => {
        // if (!teamId) return;
        // dispatch(setCurrentTeamById(teamId));

        // const team = projectTeamsList?.find((team: any) => {
        //     return team.id === teamId;
        // });

        // if (!team) return;
        // const project = projectsList?.find((project: any) => {
        //     return project.id === team.entity_id;
        // });

        // if (!project) return;
        // dispatch(setCurrentProject(project));
        // setCurrentTeamByProjectId(project.id);
    }, [teamId, teamsList, projectTeamsList, projectsList]);

    // const setCurrentTeamByProjectId = (projectId?: string) => {
    //     const team = projectTeamsList?.find((team: any) => {
    //         return team.entity_id === projectId;
    //     });

    //     if (!team) return;
    //     navigateToProject(team.id);
    //     dispatch(setCurrentTeam(team));
    // };

    useEffect(() => {
        // dispatch(fetchTeams());
        // dispatch(fetchProjects(null));
        // dispatch(resetCurrentTeam());
        // dispatch(resetCurrentProject());
    }, [currentTenant]);

    useEffect(() => {
        fetchData();
        // TODO: figure out if these are useful or can be removed
        // dispatch(fetchIntegrations());
        // dispatch(fetchPermissions());
    }, [currentTenant, currentTeam]);

    const fetchData = () => {
        dispatch(fetchDocuments(categoryId));
        dispatch(fetchCategories(null));
    };

    const navigateTo = (categoryId?: number | null) => {
        let to: string = '/documents';

        // if (teamId) to += `/p/${teamId}`;
        if (categoryId) to += `/${categoryId}`;

        navigate(to);
    };

    // const navigateToProject = (teamId: string) => {
    //     let to: string = `/documents/p/${teamId}`;
    //     navigate(to);
    // };

    const handleSyncIntegration = (integration: any) => {
        dispatch(syncIntegration(integration.id));
    };

    // const handleConfirmTeamDelete = () => {
    //     if (!currentTeam) return;

    //     dispatch(softDeleteTeam(currentTeam.id))
    //         .unwrap()
    //         .then(() => {
    //             dispatch(fetchTeams());
    //             dispatch(resetCurrentTeam());
    //             dispatch(resetCurrentProject());
    //             navigate('/documents');
    //         }).catch(() => { });
    // };

    // TODO: maybe refactor
    const openGuestTeamModal = (item: any) => {
        let team: any = null;
        if (item.type === 'category') {
            team = teams[item?.uuid];
        } else {
            team = teams[item?.id];
        }
        setCurrentEntity(item);

        if (team) {
            guestTeamMembersUpdateModal.current?.open();
        } else {
            guestTeamCreateModal.current?.open();
        }
    };

    const openMoveModal = () => {
        if (!currentBreadcrumb) return;
        dispatch(setExcludedCategories([currentBreadcrumb]));
        moveRowModal.current?.open();
    };
    const moveCurrentBreadcrumb = (destination: any) => {
        let promises: any[] = [];
        promises.push(dispatch(moveCategory({
            id: currentBreadcrumb.id,
            parent_id: destination?.id || null,
        })));

        Promise.all(promises).then((_) => {
            dispatch(fetchDocuments(currentBreadcrumb.id));
        }).catch(() => { });
    };

    const handleConfirmBreadcrumbDelete = () => {
        if (!currentBreadcrumb) return;
        const nextBreadcrumb = breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2] : null;
        dispatch(softDeleteCategory(currentBreadcrumb.id))
            .unwrap()
            .then(() => {
                navigateTo(nextBreadcrumb.id);
                dispatch(fetchDocuments(nextBreadcrumb.id));
            }).catch(() => { });
    };

    const breadcrumbPopupState = usePopupState({ variant: 'popover', popupId: 'itemsMenu' });
    const rootBreadcrumbPopupState = usePopupState({ variant: 'popover', popupId: 'teamsMenu' });
    const itemPopupState = usePopupState({ variant: 'popover', popupId: 'itemsMenu' });
    const teamsPopupState = usePopupState({ variant: 'popover', popupId: 'teamsMenu' });

    return <>
        <Grid item xs={8} sx={{ pb: 2 }}>
            <CustomBreadCrumbs
                title={currentTeam ? currentTeam.name : 'Documents'}
                loading={loading}
                breadcrumbs={breadcrumbs}
                onRootClick={() => {
                    navigateTo();
                    dispatch(fetchDocuments(null));
                }}
                onClick={(breadcrumb) => {
                    navigateTo(breadcrumb.id);
                    dispatch(fetchDocuments(breadcrumb.id));
                }}
                onMenuClick={(event: React.MouseEvent<HTMLElement>, breadcrumb: any) => {
                    setCurrentBreadcrumb({
                        ...breadcrumb,
                        type: 'category',
                    })
                    breadcrumbPopupState.setOpen(true, event.currentTarget);
                }}
                onAccessClick={(event: React.MouseEvent<HTMLElement>) => {
                    rootBreadcrumbPopupState.setOpen(true, event.currentTarget);
                }}
            />
        </Grid>
        {/* <Grid item xs={4} sx={{ pt: 2, pb: 1 }}>
            <Stack direction="row" spacing={2} alignItems="center">
                <ButtonGroup variant="text">
                    <Button
                        color="inherit"
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            itemPopupState.setOpen(true, event.currentTarget);
                        }}
                    >
                        {currentProject?.name ?? 'Select Project'}
                    </Button>
                    {currentProject &&
                        <Button
                            color="inherit"
                            size="small"
                            onClick={() => {
                                dispatch(resetCurrentProject());
                                dispatch(resetCurrentTeam());
                                navigate('/documents');
                            }}
                        >
                            <Close />
                        </Button>
                    }
                    <Button
                        color="inherit"
                        size="small"
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            teamsPopupState.setOpen(true, event.currentTarget);
                        }}
                    >
                        Select Shared Folder
                    </Button>
                </ButtonGroup>
                <CustomDropdown
                    {...bindMenu(itemPopupState)}
                    loading={projectsLoading}
                    createLabel={'Create Project'}
                    onCreate={() => {
                        projectTeamCreateModal.current?.open();
                    }}
                    onSelect={(item: any) => {
                        dispatch(setCurrentProject(item));
                        setCurrentTeamByProjectId(item.id);
                    }}
                    onDebounce={(value: string, callback: (value: any) => void) => {
                        dispatch(fetchProjects(value))
                            .unwrap()
                            .then((response: any) => {
                                callback(response.data.map((item: any) => {
                                    return {
                                        title: item.number ? `${item.name} (${item.number})` : item.name,
                                        ...item
                                    };
                                }));
                            }).catch(() => { });
                    }}
                ></CustomDropdown>
                <CustomDropdown
                    {...bindMenu(teamsPopupState)}
                    loading={loading}
                    createLabel={'Create Shared Folder'}
                    onCreate={() => {
                        teamCreateModal.current?.open();
                    }}
                    onSelect={(item: any) => {
                        if (item) navigate('/documents/t/' + item.id);
                    }}
                    onDebounce={(value: string, callback: (value: any) => void) => {
                        dispatch(searchTeams(value))
                            .unwrap()
                            .then((response: any) => {
                                const items = response.data
                                    .filter((team: Team) => {
                                        return team.type === 'custom' && team.entity_id === null;
                                    })
                                    .map((team: Team) => {
                                        return {
                                            title: team.name,
                                            ...team,
                                        };
                                    });

                                callback(items);
                            }).catch(() => { });
                    }}
                ></CustomDropdown>
            </Stack>
        </Grid> */}

        <DocumentsTable list={list as Documents} categoryId={categoryId} goTo={navigateTo} fetchData={fetchData} />

        {/* Menus */}
        <Menu
            elevation={1}
            {...bindMenu(breadcrumbPopupState)}
            PaperProps={{
                style: {
                    width: 220,
                },
            }}
            MenuListProps={{
                dense: false,
                subheader: <ListSubheader>
                    <Typography variant='subtitle2' my={2} noWrap>{currentBreadcrumb?.name || currentTeam?.name}</Typography>
                </ListSubheader>,
            }}
        >
            {/* TODO: fill this in */}
            {false && <MenuItem onClick={() => { }}>
                <ListItemIcon>
                    <Share fontSize="small" />
                </ListItemIcon>
                <ListItemText>Share</ListItemText>
            </MenuItem>}
            <MenuItem onClick={() => {
                breadcrumbPopupState.close();
                openGuestTeamModal(currentBreadcrumb);
            }}>
                <ListItemIcon>
                    <Share fontSize="small" />
                </ListItemIcon>
                <ListItemText>Manage Sharing</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => {
                openMoveModal();
            }}>
                <ListItemIcon>
                    <DriveFileMove fontSize="small" />
                </ListItemIcon>
                <ListItemText>Move</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => {
                confirmDeleteBreadcrumbModal?.current?.open();
            }}>
                <ListItemIcon>
                    <DeleteSweep fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
        </Menu>
        <Menu
            elevation={1}
            {...bindMenu(rootBreadcrumbPopupState)}
            PaperProps={{
                style: {
                    width: 220,
                },
            }}
            MenuListProps={{
                dense: false,
                subheader: <ListSubheader>
                    <Typography variant='subtitle2' my={2} noWrap>{currentTeam ? currentTeam?.name : 'Documents'}</Typography>
                </ListSubheader>,
            }}
        >
            <MenuItem onClick={() => teamRenameModal.current?.open()}>
                <ListItemIcon>
                    <DriveFileMove fontSize="small" />
                </ListItemIcon>
                <ListItemText>Rename</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => teamMembersUpdateModal.current?.open()}>
                <ListItemIcon>
                    <People fontSize="small" />
                </ListItemIcon>
                <ListItemText>Manage members</ListItemText>
            </MenuItem>
            {integrations && integrations.length > 0 && <Divider />}
            {integrations && integrations.map((integration: any, index: number) => {
                return integration.status === 'active' &&
                    <MenuItem key={index} onClick={() => {
                        handleSyncIntegration(integration);
                    }}>
                        <ListItemIcon>
                            <SyncAlt fontSize="small" />
                        </ListItemIcon>
                        <ListItemText sx={{ textTransform: 'capitalize' }}>{integration.name} Sync</ListItemText>
                    </MenuItem>
            })
            }
            <Divider />
            <MenuItem onClick={() => confirmTeamDeleteModal.current?.open()}>
                <ListItemIcon>
                    <DeleteSweep fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
        </Menu>

        <ProjectsContext.Provider value={currentProject}>
            <ProjectTeamCreateModal
                ref={projectTeamCreateModal}
                onClose={fetchData}
            ></ProjectTeamCreateModal>
        </ProjectsContext.Provider>

        <TeamsContext.Provider value={currentTeam}>
            <TeamCreateModal
                ref={teamCreateModal}
                onClose={fetchData}
            ></TeamCreateModal>
            <TeamRenameModal
                ref={teamRenameModal}
                onClose={fetchData}
            ></TeamRenameModal>
            <TeamMembersUpdateModal
                ref={teamMembersUpdateModal}
                onClose={fetchData}
            ></TeamMembersUpdateModal>
        </TeamsContext.Provider>

        <EntityContext.Provider value={currentEntity}>
            <GuestTeamCreateModal
                ref={guestTeamCreateModal}
                onClose={fetchData}
            ></GuestTeamCreateModal>
            <GuestTeamMembersUpdateModal
                ref={guestTeamMembersUpdateModal}
                onClose={fetchData}
            ></GuestTeamMembersUpdateModal>
        </EntityContext.Provider>

        <ConfirmationModal
            ref={confirmDeleteBreadcrumbModal}
            text='Are you sure want to delete item?'
            onConfirm={handleConfirmBreadcrumbDelete}
        ></ConfirmationModal>
        {/* <ConfirmationModal
            ref={confirmTeamDeleteModal}
            text='Are you sure want to delete item?'
            onConfirm={handleConfirmTeamDelete}
        ></ConfirmationModal> */}
        <CategoryShareModal
            ref={categoryShareModal}
            onClose={fetchData}
        ></CategoryShareModal>
        <CategoriesTreeModal
            ref={moveRowModal}
            title={`Move category to...`}
            submitLabel='Move'
            onSubmit={moveCurrentBreadcrumb}
        ></CategoriesTreeModal>
    </>;
};

export default DefaultDocumentsPage;
