
import {
    useEffect,
    useRef,
} from 'react';
import {
    useAppDispatch,
    useAppSelector
} from 'utilities/hooks';
import SitemaxTable, { SitemaxTableHeader } from '../../../containers/SitemaxTable/SitemaxTable';
import { fetchUsers } from 'slices/usersActions';
import { GridCallbackDetails, GridCellParams, MuiEvent } from '@mui/x-data-grid-premium';
import { Button, Grid, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import UserModal from '../modals/UserModal';
import { BaseModalRef } from 'modals/BaseModal';

const UsersPage = () => {
    const dispatch = useAppDispatch();

    const { currentTenant } = useAppSelector((state) => state.tenants);
    const { currentProject } = useAppSelector((state) => state.projects);
    const userModalRef = useRef<BaseModalRef>(null);

    const {
        users,
        loading,
    } = useAppSelector((state) => state.users);

    useEffect(() => {
        fetchData();
    }, [currentTenant]);

    const fetchData = (search: string = '') => {
        dispatch(fetchUsers(search));
    };

    var headers: SitemaxTableHeader[] = [
        // {
        //     field: 'name',
        //     headerName: 'Name',
        //     renderCell: (row: any) => <>
        //         {row.name_first} {row.name_last}
        //     </>,
        // },
        {
            field: 'name',
            headerName: 'Name',
        },
        {
            field: 'email',
            headerName: 'Email',
        },
        {
            field: 'phone',
            headerName: 'Phone',
        },
    ];

    return <>
        <Grid container sx={{ pb: 2 }}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={() => {
                            userModalRef?.current?.open();
                        }}
                        startIcon={<Add />}
                    >
                        Create User
                    </Button>
                </Stack>
            </Grid>
        </Grid>
        <SitemaxTable
            headers={headers}
            rows={users || [] as Array<any>}
            getData={() => {
                fetchData();
            }}
            onCellClick={(params: GridCellParams, event: MuiEvent, details: GridCallbackDetails) => {
                // if (params.field != 'name') return;
                // if (params.row.type === 'category') {
                //     handleCategory(params.row);
                // } else {
                //     handleDocumentOpen(params.row);
                // }
            }}
            loading={loading}
        // filtersModal={<FilterModal />}
        // checkboxSelection={true}
        // actions={actions}
        ></SitemaxTable>
        <UserModal
            ref={userModalRef}
            onSubmit={fetchData}
        />
    </>;
};

export default UsersPage;
