import {
    LoadingButton,
    TabContext,
    TabList,
    TabPanel
} from '@mui/lab';
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Tab
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import ContactsAutocomplete from 'components/ContactsAutocomplete';
import InvitationsList from 'components/InvitationsList';
import MembersList from 'components/MembersList';
import UsersAutocomplete from 'components/UsersAutocomplete';
import { EntityContext, TeamMemberRole, TeamMemberRoleOptions } from 'interfaces/teams';
import { User, Users } from 'interfaces/users';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import ConfirmationModal from 'modals/ConfirmationModal';
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { fetchCompanies, fetchContacts } from 'slices/contactsActions';
import { fetchInvitations, fetchUsersForInvitation, syncInvitations } from 'slices/invitationsActions';
import { reset as resetTeam } from 'slices/teamSlice';
import { fetchGuestTeam, fetchGuestTeamMembers, softDeleteGuestTeam, updateGuestTeamMembers } from 'slices/teamsActions';
import { fetchUsers } from 'slices/usersActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

export interface GuestTeamMembersUpdateModalProps {
    onSubmit?: () => void;
    onClose?: () => void;
};

const GuestTeamMembersUpdateModal = (
    props: GuestTeamMembersUpdateModalProps,
    ref: React.Ref<unknown>
) => {
    const entity = useContext(EntityContext);
    const entityId = entity?.type === 'category' ? entity?.uuid : entity?.id;
    const confirmRemoveAccessModal  = useRef<BaseModalRef>(null);
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const {
        loading: teamLoading,
        error: teamError,
        team: guestTeam,
        members: teamMembers,
        membersMap: teamMembersMap
    } = useAppSelector((state) => state.team);
    const {
        loading: usersLoading,
        error: usersError
    } = useAppSelector((state) => state.users);
    const {
        loading: invitationsLoading,
        invitations
    } = useAppSelector((state) => state.invitations);
    const {
        loading: contactsLoading,
        error: contactsError
    } = useAppSelector((state) => state.contacts);
    const { user } = useAppSelector((state) => state.account);
    const [membersSelected, setMembersSelected] = useState<any[]>([]);
    const [invitationsSelected, setInvitationsSelected] = useState<any[]>([]);
    const [contactsSelected, setContactsSelected] = useState<any[]>([]);
    const [role, setRole] = useState(TeamMemberRole.VIEWER);
    const [invitationRole, setInvitationRole] =  useState(TeamMemberRole.VIEWER);
    const [contactRole, setContactRole] =  useState(TeamMemberRole.VIEWER);
    const [isOpen, setIsOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState('1');
    const [contactCompany, setContactCompany] = useState<any>();

    useEffect(() => {
        if (!isOpen) return;

        resetDefaults();
        dispatch(fetchGuestTeam(entityId));
        dispatch(fetchUsers(''));
    }, [isOpen]);

    useEffect(() => {
        if (!guestTeam) return;

        if (entityId) dispatch(fetchGuestTeamMembers(entityId));
        dispatch(fetchInvitations(guestTeam.id));
    }, [guestTeam, currentTab]);

    const onDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchUsers(value))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => {});
    };

    const onInvitationsDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchUsersForInvitation(value))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => {});
    };

    const onContactsDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchContacts({
            search: value,
            parent: contactCompany?.id,
        }))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => {});
    };

    const onCompaniesDebounce = (value: string, callback: (value: any) => void) => {
        dispatch(fetchCompanies(value))
            .unwrap()
            .then((response: any) => {
                callback(response.data);
            }).catch(() => {});
    };

    const resetDefaults = () => {
        setRole(TeamMemberRole.VIEWER);
        setMembersSelected([]);
        setInvitationRole(TeamMemberRole.VIEWER);
        setInvitationsSelected([]);
        setContactRole(TeamMemberRole.VIEWER);
        setContactCompany(null);
    };

    const onSubmit = () => {
        if (!guestTeam) return;
        const promises: any[] = [];

        const members: any[] = [];
        membersSelected.forEach((member: User) => {
            members.push({
                id: member.id,
                role: role,
            });
        });
        teamMembers?.forEach((member: User) => {
            members.push({
                id: member.id,
                role: teamMembersMap[member.id].role,
            });
        });

        const _invitations: any[] = [];
        invitationsSelected.forEach((invitation: any) => {
            _invitations.push({
                id: invitation.id,
                email: invitation.email,
                phone: invitation.phone,
                team_role: invitationRole,
            });
        });
        contactsSelected.forEach((contact: any) => {
            _invitations.push({
                id: contact.id,
                email: contact.email,
                phone: contact.phone,
                team_role: contactRole,
            });
        });

        invitations?.forEach((invitation: any) => {
            _invitations.push({
                id: invitation.id,
                team_role: invitation.team_role,
            });
        });

        if (members.length) {
            promises.push(dispatch(updateGuestTeamMembers({
                entityId: entityId,
                members: members
            })));
        }

        if (_invitations.length) {
            promises.push(dispatch(syncInvitations({
                teamId: guestTeam.id,
                invitations: _invitations
            })));
        }

        Promise.all(promises)
            .then(() => {
                dispatch(fetchGuestTeam(entityId));
                props.onSubmit && props.onSubmit();
                close();
            }).catch(() => {});
    };

    const handleRemoveAccess = () => {
        confirmRemoveAccessModal.current?.open();
    };

    const handleConfirmRemoveAccess = () => {
        dispatch(softDeleteGuestTeam(entityId))
            .unwrap()
            .then(() => {
                props.onSubmit && props.onSubmit();
                close();
            }).catch(() => {});
    };

    const handleMembersSelected = (members: Users) => {
        setMembersSelected(members);
    };

    const handleRoleChange = (event: SelectChangeEvent) => {
        setRole(event.target.value as TeamMemberRole);
    };

    const handleInvitationsSelected = (invitations: any[]) => {
        setInvitationsSelected(invitations);
    };

    const handleInvitationRoleChange = (event: SelectChangeEvent) => {
        setInvitationRole(event.target.value as TeamMemberRole);
    };

    const handleContactsSelected = (contacts: any[]) => {
        setContactsSelected(contacts);
    };

    const handleContactRoleChange = (event: SelectChangeEvent) => {
        setContactRole(event.target.value as TeamMemberRole);
    };

    /**
     * Modal
     */
    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const handleOnClose = () => {
        dispatch(resetTeam());
        props.onClose && props.onClose();
    };

    return <>
        <BaseModal
            ref={modal}
            title={'Manage sharing'}
            maxWidth={'md'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleOnClose}
            actions={
                <Box sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <Button
                        color="error"
                        disableElevation
                        onClick={handleRemoveAccess}
                        sx={{marginRight: "auto"}}
                    >
                        Stop Sharing
                    </Button>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        onClick={onSubmit}
                        loading={teamLoading || invitationsLoading}
                        color="primary"
                        disableElevation
                    >
                        Save
                    </LoadingButton>
                </Box>
            }
        >
            {(isOpen) ?
                <Stack spacing={2}>
                    <TabContext value={currentTab}>
                        <TabList onChange={(event: React.SyntheticEvent, tabIndex: string) => {
                            setCurrentTab(tabIndex);
                            resetDefaults();
                        }}>
                            <Tab
                                label={"Add members"}
                                value="1"
                            />
                            <Tab
                                label={"Send Invitations"}
                                value="2"
                            />
                            {/* <Tab
                                label={"Invite Contacts"}
                                value="3"
                            /> */}
                        </TabList>
                        <TabPanel value="1" sx={{p: 0}}>
                            <Stack direction="row" spacing={2}>
                                <UsersAutocomplete
                                    label="Add people"
                                    debounceOnOpen={true}
                                    onDebounce={onDebounce}
                                    onSelect={handleMembersSelected}
                                    loading={usersLoading}
                                />
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                        value={role}
                                        onChange={handleRoleChange}
                                    >
                                        {TeamMemberRoleOptions.map((option: any) => {
                                            return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </TabPanel>
                        <TabPanel value="2" sx={{p: 0}}>
                            <Stack direction="row" spacing={2}>
                                <UsersAutocomplete
                                    label="Add by email or phone number"
                                    onDebounce={onInvitationsDebounce}
                                    onSelect={handleInvitationsSelected}
                                    loading={usersLoading}
                                />
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                        value={invitationRole}
                                        onChange={handleInvitationRoleChange}
                                    >
                                        {TeamMemberRoleOptions.map((option: any) => {
                                            return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </TabPanel>
                        {/* <TabPanel value="3" sx={{p: 0}}>
                            <Stack direction="row" spacing={2}>
                                <ContactsAutocomplete
                                    label="Find company"
                                    debounceOnOpen={true}
                                    onDebounce={onCompaniesDebounce}
                                    onChange={(value: any) => {
                                        setContactCompany(value);
                                    }}
                                    loading={contactsLoading}
                                />
                                <UsersAutocomplete
                                    label="Add contacts"
                                    debounceOnOpen={true}
                                    onDebounce={onContactsDebounce}
                                    onSelect={handleContactsSelected}
                                    loading={contactsLoading}
                                    dependent={contactCompany}
                                />
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                        value={contactRole}
                                        onChange={handleContactRoleChange}
                                    >
                                        {TeamMemberRoleOptions.map((option: any) => {
                                            return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </TabPanel> */}
                    </TabContext>
                    {
                        teamMembers ?
                        <MembersList
                            members={teamMembers}
                            membersMap={teamMembersMap}
                            membersConstrained={user && [user.id]}
                        ></MembersList>
                        : <Skeleton variant="rounded" width={'100%'} height={56} sx={{mt: 1}}/>
                    }
                    {
                        invitations ?
                        <InvitationsList
                            invitations={invitations}
                            onResend={() => console.log(0)}
                        ></InvitationsList>
                        : <Skeleton variant="rounded" width={'100%'} height={56}/>
                    }
                </Stack>
                : <Skeleton variant="rounded" width={'100%'} height={56}/>
            }
        </BaseModal>
        <ConfirmationModal
            ref={confirmRemoveAccessModal}
            text='Are you sure want to stop sharing?'
            onConfirm={handleConfirmRemoveAccess}
        ></ConfirmationModal>
    </>;
};

export default forwardRef(GuestTeamMembersUpdateModal);