import {
    Delete,
} from '@mui/icons-material';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Skeleton,
    SxProps,
    Theme,
    Typography
} from '@mui/material';
import { BarChart, LineChart, PieChart } from '@mui/x-charts';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { nanoid } from '@reduxjs/toolkit';
import { GridStack, GridStackOptions } from 'gridstack';

import { DashboardsContext } from 'modules/dashboards/interfaces/dashboards';
import {
    createElement,
    createRef,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { fetchDashboardWidgetData } from 'modules/dashboards/slices/dashboardsActions';
import {
    useAppDispatch,
} from 'utilities/hooks';
import MiniStatisticsCard from 'containers/Cards/StatisticsCards/MiniStatisticsCard/MiniStatisticsCard';
import WBox from 'containers/WBox';

export interface ChartProps {
    id: string;
    name: string;
};

export interface BarChartProps extends ChartProps {
    config: Array<any>;
};

const BarChartWithData = (props: BarChartProps) => {
    const dispatch = useAppDispatch();
    const dashboard = useContext(DashboardsContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        dispatch(fetchDashboardWidgetData({
            dashboard: dashboard?.id,
            widget: props.name,
        }))
            .unwrap()
            .then((res: any) => {
                if (!res) return;
                if (!res.data) return;
                setData(res.data);
            });
    }, []);

    if (!data) return <Skeleton variant="rounded" width={520} height={260} />;

    return <BarChart
        xAxis={props.config}
        series={data}
        width={520}
        height={260}
    />;
};

const LineChartWithData = (props: ChartProps) => {
    const dispatch = useAppDispatch();
    const dashboard = useContext(DashboardsContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        dispatch(fetchDashboardWidgetData({
            dashboard: dashboard?.id,
            widget: props.name,
        }))
            .unwrap()
            .then((res: any) => {
                if (!res) return;
                if (!res.data) return;
                setData(res.data);
            });
    }, []);

    const xLabels = [
        'Page A',
        'Page B',
        'Page C',
        'Page D',
        'Page E',
        'Page F',
        'Page G',
    ];

    if (!data) return <Skeleton variant="rounded" width={520} height={260} />;

    return <LineChart
        width={520}
        height={260}
        series={data}
        xAxis={[{ scaleType: 'point', data: xLabels }]}
        yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
        rightAxis="rightAxisId"
    />;
};

const PieChartWithData = (props: ChartProps) => {
    const dispatch = useAppDispatch();
    const dashboard = useContext(DashboardsContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        dispatch(fetchDashboardWidgetData({
            dashboard: dashboard?.id,
            widget: props.name,
        }))
            .unwrap()
            .then((res: any) => {
                if (!res) return;
                if (!res.data) return;
                setData(res.data);
            });
    }, []);

    if (!data) return <Skeleton variant="rounded" width={400} height={200} />;

    return <PieChart
        series={data}
        width={400}
        height={200}
    />;
};

const SiteMaxFormsListCreatedIn30Days = (props: any) => {
    const dispatch = useAppDispatch();
    const dashboard = useContext(DashboardsContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        dispatch(fetchDashboardWidgetData({
            dashboard: dashboard?.id,
            widget: props.name,
        }))
            .unwrap()
            .then((res: any) => {
                if (!res) return;
                if (!res.data) return;
                setData(res.data);
            });
    }, []);

    if (!data) return <Skeleton variant="rounded" width={400} height={200} />;

    const columns: GridColDef<(typeof data)[number]>[] = [
        {
            field: 'name',
            headerName: 'Project',
            flex: 1,
        },
        {
            field: 'project_date_safety_count',
            headerName: 'Safety Report',
            flex: 1,
        },
        {
            field: 'project_date_progress_count',
            headerName: 'Site Report',
            flex: 1,
        },
        {
            field: 'company_progress_count',
            headerName: 'Company Report',
            flex: 1,
        },
        {
            field: 'tool_progress_count',
            headerName: 'Equipment Report',
            flex: 1,
        },
    ];

    return (
        <WBox sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
            />
        </WBox>
    );
}

const SiteMaxDocumentsStorageInfo = (props: any) => {
    const dispatch = useAppDispatch();
    const dashboard = useContext(DashboardsContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        dispatch(fetchDashboardWidgetData({
            dashboard: dashboard?.id,
            widget: props.name,
        }))
            .unwrap()
            .then((res: any) => {
                console.log(res);

                if (!res) return;
                if (!res.data) return;
                setData(res.data);
            });
    }, []);

    if (!data) return <Skeleton variant="rounded" width={400} height={200} />;

    return <WBox>
        <Typography variant="h6" gutterBottom>Storage Info</Typography>
        <PieChart
            series={data}
            width={400}
            height={200}
        />
    </WBox>;
}

const SiteMaxToolsMaintenanceExpiring = (props: any) => {
    const dispatch = useAppDispatch();
    const dashboard = useContext(DashboardsContext);
    const [data, setData] = useState<{ text: string | null }>({ text: null });

    useEffect(() => {
        dispatch(fetchDashboardWidgetData({
            dashboard: dashboard?.id,
            widget: props.name,
        }))
            .unwrap()
            .then((res: any) => {
                if (!res) return;
                if (!res.data) return;
                setData(res.data);
            });
    }, []);

    if (!data) return <Skeleton variant="rounded" width={400} height={200} />;

    return <MiniStatisticsCard
        bgColor="secondary"
        title={{ text: "Equipments Maintenance", fontWeight: "medium" }}
        count={data.text}
        icon={{ component: "info" }}
    />;
}

const SiteMaxUsersRecordsExpiring = (props: any) => {
    const dispatch = useAppDispatch();
    const dashboard = useContext(DashboardsContext);
    const [data, setData] = useState<{ text: string | null }>({ text: null });

    useEffect(() => {
        dispatch(fetchDashboardWidgetData({
            dashboard: dashboard?.id,
            widget: props.name,
        }))
            .unwrap()
            .then((res: any) => {
                console.log(res);

                if (!res) return;
                if (!res.data) return;
                setData(res.data);
            });
    }, []);

    if (!data) return <Skeleton variant="rounded" width={400} height={200} />;

    return <MiniStatisticsCard
        bgColor="primary"
        title={{ text: "People Records", fontWeight: "medium" }}
        count={data.text}
        icon={{ component: "person" }}
    />;
}

export interface WidgetProps {
    id: string;
    name: string;
    props?: any;
    children?: any;
};

const Widget = (props: WidgetProps) => {
    switch (props.name) {
        case 'Typography':
            return <Typography {...props?.props}>{props?.children}</Typography>;
        case 'BarChartWithData':
            return <BarChartWithData id={props.id} name={props.name} {...props?.props} />;
        case 'LineChartWithData':
            return <LineChartWithData id={props.id} name={props.name} {...props?.props} />;
        case 'PieChartWithData':
            return <PieChartWithData id={props.id} name={props.name} {...props?.props} />;
        case 'SiteMaxFormsListCreatedIn30Days':
            return <SiteMaxFormsListCreatedIn30Days id={props.id} name={props.name} {...props?.props} />;
        case 'SiteMaxToolsMaintenanceExpiring':
            return <SiteMaxToolsMaintenanceExpiring id={props.id} name={props.name} {...props?.props} />;
        case 'SiteMaxUsersRecordsExpiring':
            return <SiteMaxUsersRecordsExpiring id={props.id} name={props.name} {...props?.props} />;
        case 'SiteMaxDocumentsStorageInfo':
            return <SiteMaxDocumentsStorageInfo id={props.id} name={props.name} {...props?.props} />;
    }

    return createElement(props.name, props?.props, props?.children);
};

export interface GridStackInterface { };
export interface GridStackContainerInterface { };

export interface ControlledGridStackRef {
    save: () => Array<any>;
};

export interface ControlledGridStackProps {
    sx?: SxProps<Theme>,
    items: Array<any>;
    options?: GridStackOptions;
    onChange?: () => void;
    onDelete?: (id: string) => void;
};

const ControlledGridStack = (
    props: ControlledGridStackProps,
    ref: React.Ref<unknown>
) => {
    const {
        sx,
        items,
        options,
        onChange,
        onDelete
    } = props;
    const dashboard = useContext(DashboardsContext);
    const [gridStackId, setGridStackId] = useState<string>('grid_stack_' + nanoid());
    const [gridStack, setGridStack] = useState<GridStack>();
    const gridRef = useRef<HTMLDivElement>(null);
    const refs = useRef([]);

    if (refs.current.length !== items.length) {
        items.forEach((item) => {
            refs.current[item.id] = refs.current[item.id] || createRef();
        });
    }

    useLayoutEffect(() => {
        if (!gridRef.current) return;

        // Do not re-init if we already have a grid
        const grid = gridStack ?? GridStack.init(options ?? {
            float: false,
            sizeToContent: true,
        },
            gridRef.current
        ).on('change', () => {
            onChange && onChange();
        });
        setGridStack(grid);

        // Draw the items
        grid.batchUpdate();
        grid.removeAll(false);
        items.forEach((item) => {
            const ref = refs.current[item.id] as React.RefObject<HTMLElement>;
            if (ref && ref.current) {
                // didn`t work to pass props
                // return grid.makeWidget(ref.current, item.props);
                return grid.makeWidget(ref.current);
            }
        });
        grid.batchUpdate(false);

    }, [gridRef.current, items]);

    const handleSave = () => {
        if (!gridStack) return;

        const serialized = gridStack.save() as Record<string, any>;
        if (!serialized) return;

        let serializedMap: Record<string, any> = {};
        serialized.forEach((item: any) => {
            delete item['content'];
            serializedMap[item.id] = item;
        });

        return items.map((item) => {
            return {
                ...item,
                position: serializedMap[item.id],
            };
        });
    };

    useImperativeHandle(ref, () => ({
        save: () => handleSave(),
    }));

    return <Box className={`grid-stack ${gridStackId}`} ref={gridRef} sx={sx}>
        {items.map((item) => {
            return <div
                className="grid-stack-item"
                ref={refs.current[item.id]}
                key={item.id}
                gs-id={item.position.id}
                gs-w={item.position.w}
                gs-h={item.position.h}
                gs-x={item.position.x}
                gs-y={item.position.y}
            >
                {/* <Box className="grid-stack-item-content"> */}
                    {/* <Card variant="outlined">
                        <CardHeader
                            action={
                                <IconButton onClick={() => onDelete && onDelete(item.id)}>
                                    <Delete />
                                </IconButton>
                            }
                            title={item.title}
                            subheader={item.subtitle}
                        />
                        <CardContent> */}
                        <Card className="grid-stack-item-content" variant="outlined">
                            <Widget id={item.id} {...item.widget} />
                        </Card>
                    {/* </CardContent>
                        <CardActions disableSpacing></CardActions> */}
                    {/* </Card> */}
                {/* </Box> */}
            </div>;
        })}
    </Box>;
};

export default forwardRef(ControlledGridStack);