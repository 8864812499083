import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSitemaxUIController, setMiniSidenav } from 'context';
import { Box } from '@mui/material';

import WBox from 'containers/WBox';
import DefaultAppBar from 'components/DefaultAppBar';
import SideNav from 'components/SideNav';

const TopLayout = () => {
    const [controller, dispatchUI] = useSitemaxUIController();
    const { miniSidenav } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);

    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatchUI, false);
            setOnMouseEnter(true);
        }
    };

    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatchUI, true);
            setOnMouseEnter(false);
        }
    };

    return <>
        <SideNav
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
        />
        <WBox
            sx={({ breakpoints, transitions, functions: { pxToRem } }: any) => ({
                p: 3,
                position: "relative",

                [breakpoints.up("xl")]: {
                    marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
                    transition: transitions.create(["margin-left", "margin-right"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },
            })}
        >
            <DefaultAppBar />
            <WBox
                sx={() => ({
                    mt: 3,
                    p: 3,
                    backgroundColor: '#FFFFFF', // NOTE: TEMP replace with proper theme variables
                    boxShadow: '0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05)',
                    borderRadius: '1rem',
                })}
            >
                <Box id="notification-bar"></Box>
                <Outlet />
            </WBox>
        </WBox>
    </>;
};

export default TopLayout;