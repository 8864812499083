import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Skeleton,
    TextField
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { createProject, updateProject } from 'modules/projects/slices/projectsActions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

interface Project {
    id?: string;
    name: string;
    number: string;
};

export interface ProjectCreateModalProps {
    parent?: string;
    onSubmit?: () => void;
    onClose?: () => void;
};

const ProjectCreateModal = (
    props: ProjectCreateModalProps,
    ref: React.Ref<unknown>
) => {
    const modal = useRef<BaseModalRef>(null);
    const dispatch = useAppDispatch();
    const { loading, error } = useAppSelector((state) => state.project);
    const { register, handleSubmit, reset } = useForm();
    const [isOpen, setIsOpen] = useState(false);
    const [project, setProject] = useState<Project | undefined>(undefined);
    const formId = 'project_create_form_' + nanoid();

    useEffect(() => {
        if (isOpen) {
            reset(project);
        }
    }, [isOpen]);

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const onSubmit = (data: any) => {
        if (props.parent) data.parent = props.parent;

        if (project && project.id) {
            data.id = project.id;
            dispatch(updateProject(data))
                .unwrap()
                .then(() => {
                    props.onSubmit && props.onSubmit();
                    modal?.current?.close();
                }).catch(() => {});
        } else {
            dispatch(createProject(data))
                .unwrap()
                .then(() => {
                    props.onSubmit && props.onSubmit();
                    modal?.current?.close();
                }).catch(() => {});
        }
    };

    const open = (project: Project | undefined = undefined) => {
        if (project) {
            setProject(project);
        } else {
            setProject({
                name: 'Untitled Project',
                number: '0000',
            });
        }
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: (project?: Project | undefined) => open(project),
        close: () => close(),
    }));

    return <>
        <BaseModal
            ref={modal}
            title={'Create project'}
            maxWidth={'xs'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleClose}
            actions={
                <>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={close}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        form={formId}
                        loading={loading}
                        color="primary"
                        disableElevation
                    >
                        { project && project.id ? 'Update' : 'Create' }
                    </LoadingButton>
                </>
            }
        >
            {(isOpen) ?
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        id="name"
                        variant="outlined"
                        label="Project Name"
                        {...register("name")}
                        error={!!error?.errors?.name}
                        helperText={error?.errors?.name?.[0]}
                        autoFocus
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        id="number"
                        variant="outlined"
                        label="Project Number"
                        {...register("number")}
                        error={!!error?.errors?.number}
                        helperText={error?.errors?.number?.[0]}
                        autoFocus
                        fullWidth
                        required
                    />
                </form>
                : <Skeleton variant="rounded" width={396} height={56}/>
            }
        </BaseModal>
    </>;
};

export default forwardRef(ProjectCreateModal);