import { createAsyncThunk } from '@reduxjs/toolkit';
import { mimeTypes } from 'utilities/constants';
import { getRejectedValues, useAxios } from '../utilities/axios';

export const fetchDocument = createAsyncThunk('document/fetch', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        const response = await axios.get(`api/v1/documents/${id}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchDocumentVersions = createAsyncThunk('document/versions', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/documents/${id}/versions`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchDocumentVersion = createAsyncThunk('document/fetchVersion', async (data: { id: any, version: any }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/documents/${data.id}/versions/${data.version}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const openDocumentVersion = createAsyncThunk('document/openVersion', async (data: { id: any, version: any }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/documents/${data.id}/versions/${data.version}/open`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const setDocumentVersion = createAsyncThunk('document/setVersion', async (data: { id: any, version: any }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.put(`api/v1/documents/${data.id}/versions/`, {
            version_id: data.version
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const deleteDocumentVersion = createAsyncThunk('document/deleteVersion', async (data: { id: any, version: any }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.delete(`api/v1/documents/${data.id}/versions/${data.version}`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchMarkup = createAsyncThunk('document/fetchMarkup', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.get(`api/v1/documents/${id}/markup`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const saveMarkup = createAsyncThunk('document/saveMarkup', async ({ id, data }: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.post(`api/v1/documents/${id}/markup`, data);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const importMarkup = createAsyncThunk('document/importMarkup', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state:any = getState();
        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.post(`api/v1/documents/${id}/markup/import`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchDocuments = createAsyncThunk('document/list', async (parent_id: any | null = null, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();
        const params = state.documents.params;

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        let queryParams: any = {};
        if (parent_id) queryParams.parent_id = parent_id;
        if (params.query) queryParams.query = params.query;
        if (params.filters?.type && params.filters?.type && params.filters?.type?.value.length) {
            let mime_types: Array<string> = [];
            Object.values(params.filters?.type?.value).forEach((type: any) => {
                mime_types.push(...mimeTypes[type.id as keyof typeof mimeTypes]);
            });
            queryParams.mime_type = mime_types.join(',');
        }
        if (params.filters?.created_by && params.filters?.created_by && params.filters?.created_by?.value.length) queryParams.created_by = params.filters.created_by.value.map(function(el: any) { return el.id }).join(',');
        if (params.filters?.modified_before && params.filters?.modified_before?.value) queryParams.modified_before = params.filters.modified_before.value;
        if (params.filters?.modified_after && params.filters?.modified_after?.value) queryParams.modified_after = params.filters.modified_after.value;

        const response = await axios.get('api/v1/list/all', { params: queryParams });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchStorageCounters = createAsyncThunk('document/fetchStorageCounters', async (_, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        let params: any = {};

        const response = await axios.get('api/v1/list/storage/counters', { params: params });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchDeletedDocuments = createAsyncThunk('document/fetchDeleted', async (_, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        let params: any = {};

        const response = await axios.get('api/v1/list/trash', { params: params });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const fetchSharedDocuments = createAsyncThunk('document/fetchShared', async (parent_id: any | null = null, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        let params: any = {};
        if (parent_id) params.parent_id = parent_id;

        const response = await axios.get('api/v1/list/shared', { params: params });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});


export const doesDocumentExist = createAsyncThunk('document/doesExist', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        const response = await axios.post('api/v1/documents/exists', data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const uploadDocument = createAsyncThunk('document/upload', async (data: any, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        const response = await axios.post('api/v1/documents/upload', data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const updateDocument = createAsyncThunk('document/update', async (data: { id?: string, file: any }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        const response = await axios.post(`api/v1/documents/${data.id}/update`, data.file);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const renameDocument = createAsyncThunk('document/rename', async (data: { id: string, name: string }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        const response = await axios.post(`api/v1/documents/${data.id}/rename`, data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const moveDocument = createAsyncThunk('document/move', async (data: { id: string, category_id: number|null }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        const response = await axios.post(`api/v1/documents/${data.id}/move`, data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const copyDocument = createAsyncThunk('document/copy', async (data: { id: string, category_id: number|null }, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant, state.teams.currentTeam);

        const response = await axios.post(`api/v1/documents/${data.id}/copy`, data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const softDeleteDocument = createAsyncThunk('document/softDelete', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.delete(`api/v1/documents/${id}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const foreverDeleteDocument = createAsyncThunk('document/foreverDelete', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.delete(`api/v1/documents/${id}/forever`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const restoreDeletedDocument = createAsyncThunk('document/restoreDeleted', async (id: any, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.post(`api/v1/documents/${id}/restore`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});

export const foreverDeleteTrashed = createAsyncThunk('document/foreverDeleteTrashed', async (_, { getState, rejectWithValue }) => {
    try {
        const state: any = getState();

        if (!state.auth.accessToken) return;
        if (!state.tenants.currentTenant) return;
        const axios = useAxios(state.auth.accessToken, state.tenants.currentTenant);

        const response = await axios.delete(`api/v1/list/trash/forever`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(getRejectedValues(error));
    }
});